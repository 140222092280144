<template>
	<div class="rorcounter">
		<div class="index-top">
			<div class="index-title">
				<div class="title">出价盈亏计算器</div>
				<div class="anniu"><a href="#list"><el-button type="primary">历史记录</el-button></a></div>
			</div>
			<div class="index-prompt">
				<!-- <div class="text">出价盈亏计算器</div> -->
			</div>
		</div>
		<div class="rorcounter-main">
			<div class="gongdan">
				<div class="dan">
					<div class="text"><span>*</span>产品客单价：</div>
					<div class="inputbox">
						<el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" @input="onSubmit" v-model="guestUnit" placeholder="请输入产品客单价"></el-input>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>平台扣技术服务费(%)：</div>
					<div class="inputbox">
						<el-autocomplete
							ref="daozhang"
							popper-class="my-autocomplete"
							v-model="Bucklepoint"
							:fetch-suggestions="querySearch"
							placeholder="请输入平台扣技术服务费"
							@focus='onfocus'
							@select="handleSelect">
							<i style="margin-top:10px" class="el-icon-caret-bottom" slot="suffix" @click="$refs['daozhang'].focus()"></i>
							<template slot-scope="{ item }">
								<div class="name">{{ item.value }}</div>
							</template>
						</el-autocomplete>
						<div class="xiaohong">请直接输入数字（如：4）</div>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>签收率(%)：</div>
					<div class="inputbox">
						<el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" @input="onSubmit" v-model="receiving" placeholder="请输入签收率"></el-input>
						<div class="xiaohong">请直接输入数字（如：95）</div>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>拿货成本：</div>
					<div class="inputbox">
						<el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" @input="onSubmit" v-model="withCost" placeholder="请输入拿货成本"></el-input>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>快递费：</div>
					<div class="inputbox">
						<el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" @input="onSubmit" v-model="CourierFees" placeholder="请输入快递费"></el-input>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>转化成本：</div>
					<div class="inputbox">
						<el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" @input="onSubmit" v-model="ConversionCost" placeholder="请输入转化成本"></el-input>
					</div>
				</div>
				<div class="dan">
					<div class="text">盈亏：</div>
					<div class="inputbox">
						<div class="amount" v-if="ProfitLoss > 0">盈利￥{{(ProfitLoss).toFixed(2)}}</div>
						<div class="amount" v-if="ProfitLoss == ''">--</div>
						<div class="amount lvse" v-if="ProfitLoss < 0">亏损￥{{Math.abs((ProfitLoss).toFixed(2))}}</div>
					</div>
				</div>
				<div class="enderQueren">
					<!-- <el-button type="primary" @click="onSubmit" :disabled="delenteAnniu">计算盈亏</el-button> -->
					<el-button type="primary" @click="onbaocun" :disabled="delenteAnniu">保存记录</el-button>
					<el-button type="info" @click="onChongxin" :disabled="delenteAnniu">重新填写</el-button>
				</div>
			</div>
			<div class="biaotu">
				<div class="loudoutu"><img src="https://erlangcha.oss-cn-beijing.aliyuncs.com/usergongju/rorcounter1.png" alt=""></div>
				 <!-- <v-chart class="chart" :option="lineOption" /> -->
				 <div class="loutoutext">
					 <li>产品客单价{{guestUnit ? '：' + guestUnit :''}}</li>
					 <li>签收率{{receiving ? '：' + receiving + '%' :''}}</li>
					 <li>拿货成本{{withCost ? '：' + withCost :''}}</li>
					 <li>快递费{{CourierFees ? '：' + CourierFees :''}}</li>
					 <li>转化成本{{ConversionCost ? '：' + ConversionCost :''}}</li>
				 </div>
			</div>
		</div>
		<div class="rorcinlist" id="list">
			<div class="index-top">
				<div class="index-title">
					<div class="title">出价盈亏计算器历史记录</div>
				</div>
				<div class="index-prompt">
				</div>
			</div>
			<el-table :data="conterlist" style="width: 100%">
				<el-table-column prop="created_at" label="计算时间" >
					<template slot-scope="scope">
						{{dateFtt("yyyy-MM-dd hh:mm:ss",new Date(scope.row.created_at))}}
					</template>
				</el-table-column>
				<el-table-column prop="arpa" label="产品客单价(元)"></el-table-column>
				<el-table-column prop="deduct_dot" label="扣点(%)">
					<template slot-scope="scope">
						{{scope.row.deduct_dot ? scope.row.deduct_dot : '--'}}
					</template>
				</el-table-column>
				<el-table-column prop="receive_percent" label="签收率(%)"></el-table-column>
				<el-table-column prop="goods_cost" label="拿货成本(元)"></el-table-column>
				<el-table-column prop="express_cost" label="快递费(元)"></el-table-column>
				<el-table-column prop="conversion_cost" label="转化成本(元)"></el-table-column>
				<el-table-column prop="profit" label="盈亏(元)">
					<template slot-scope="scope">
						<div class="amount" v-if="scope.row.profit > 0">盈利￥{{scope.row.profit}}</div>
						<div class="amount lvse" v-if="scope.row.profit < 0">亏损￥{{Math.abs(scope.row.profit)}}</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="lv-page"  :page-size="per_page" :pager-count="11"  @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	 
	data () {
		return {
			guestUnit :'',//产品客单价
			Bucklepoint:'',//扣点
			restaurants: [//扣点列表
				{ value: "3"},
				{ value: "4"},
				{ value: "5"},
			],
			receiving :'',//签收率
			withCost :'',//拿货成本
			CourierFees :'',//快递费
			ConversionCost:'',//转化成本
			ProfitLoss:'',//盈亏
			delenteAnniu:false,
			lineOption:{},
			conterlist:[],
			per_page:10,//一页多少条
			lvtotal:null,//共几条数据
		}
	},

	mounted(){
		this.ongetAdvList()
	},

	methods: {
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
		querySearch(queryString, cb) {
			// 调用 callback 返回建议列表的数据
			cb(this.restaurants);
			this.onSubmit()
		},
		onfocus(){
			this.Bucklepoint = ''
		},
		handleSelect(item) {
			this.Bucklepoint = item.value
			this.onSubmit()
		},
		//计算盈亏
		onSubmit(){
			// 产品客单价*签收率-拿货成本-快递费-转化成本=盈亏
			if(this.guestUnit == '' || this.Bucklepoint == ''  || this.receiving == '' || this.withCost == '' || this.CourierFees == '' || this.ConversionCost == '' ){return}
			this.ProfitLoss = (this.guestUnit - (this.guestUnit * (this.Bucklepoint / 100)))*(this.receiving/100)-this.withCost-this.CourierFees-this.ConversionCost
		},
		onChongxin(){

		},
		// 保存
		onbaocun(){
			if(this.guestUnit == ''){this.$message({message: '请填写产品客单价', type: 'warning'}); return}
			if(this.Bucklepoint == ''){this.$message({message: '请填写扣点', type: 'warning'}); return}
			if(this.receiving == ''){this.$message({message: '请填写签收率', type: 'warning'}); return}
			if(this.withCost == ''){this.$message({message: '请填写拿货成本', type: 'warning'}); return}
			if(this.CourierFees == ''){this.$message({message: '请填写快递费', type: 'warning'}); return}
			if(this.ConversionCost == ''){this.$message({message: '请填写转化成本', type: 'warning'}); return}
			var prame = {
				arpa:this.guestUnit,
				deduct_dot:this.Bucklepoint,
				receive_percent:this.receiving,
				goods_cost:this.withCost,
				express_cost:this.CourierFees,
				conversion_cost:this.ConversionCost,
				profit:this.ProfitLoss,
			}
			this.$service.post(this.$api.roi_create,prame, (res)=> {
				if(res.code == '200'){
					this.$message({message: '保存成功', type: 'success'})
					this.onChongxin()
					this.ongetAdvList(1)
				}
			})
		},
		//重置
		onChongxin(){
			this.guestUnit  = '',//产品客单价
			this.receiving  = '',//签收率
			this.withCost  = '',//拿货成本
			this.CourierFees  = '',//快递费
			this.ConversionCost = '',//转化成本
			this.ProfitLoss = ''//盈亏
		},
		// 广告币计算列表
		ongetAdvList(val){
			var prame = {
				page:val,
				page_size:this.per_page,
			}
			this.$service.get(this.$api.roi_get_data,prame, (res)=> {
				if(res.code == '200'){
					this.conterlist = res.data.data
					this.lvtotal = res.data.total
				}
			})
		},
		//翻页
		DialogCurrentChange(val){
			this.ongetAdvList(val)
		},
	}
}
</script>

<style lang="scss" scoped>
.rorcounter{
	.gongdan .dan .text{
		min-width: 160px;
	}
	.rorcounter-main{
		margin-top: 20px;
		display: flex;
		.gongdan{
			display: block;
			width: 30%;
			.dan{
				width: 100%;
				.amount{
					color: #f00;
					font-size: 24px;
					font-weight: 500;
					&.lvse{
						color: #51bd00;
					}
				}
			}
			.enderQueren{
				width: 100%;
			}
		}
		.biaotu{
			margin-left: 50px;
			width: 400px;
			height: 400px;
			position: relative;
			.loudoutu{
				height: 100%;
				width: 100%;
				img{
					height: 100%;
					width: 100%;
				}
			}
			.loutoutext{
				li{
					position: absolute;
					top: 0;
					left: 220px;
					font-size: 18px;
					&:nth-child(1){
						top: 25px;
						color: #468ad1;
					}
					&:nth-child(2){
						top: 110px;
						color: #e95e52;
					}
					&:nth-child(3){
						top: 190px;
						color: #d69b3c;
					}
					&:nth-child(4){
						top: 270px;
						color: #a7a74a;
					}
					&:nth-child(5){
						top: 350px;
						color: #006d78;
					}
				}
			}
		}
		.rorcinlist{
			width: 40%;
		}
	}
}
@media screen and (max-width: 750px) {
	.rorcounter{
		.rorcounter-main{
			display: block;
			.gongdan{
				width: 7rem;
			}
			.biaotu{
				width: 7rem;
				height: 6rem;
				margin: .3rem 0;
				margin-left: 0;
				.loutoutext{
					li{
						position: absolute;
						top: 0;
						left: 3.9rem;
						font-size: .3rem;
						&:nth-child(1){
							top: .4rem;
							color: #468ad1;
						}
						&:nth-child(2){
							top: 1.7rem;
							color: #e95e52;
						}
						&:nth-child(3){
							top: 2.9rem;
							color: #d69b3c;
						}
						&:nth-child(4){
							top: 4.1rem;
							color: #a7a74a;
						}
						&:nth-child(5){
							top: 5.3rem;
							color: #006d78;
						}
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
	.rorcounter{
		.rorcinlist{
			.el-table{
				margin-top: 20px;
				.cell{
					text-align: center;
				}
				.amount{
					color: #f00;
					font-size: 16px;
					font-weight: 500;
					&.lvse{
						color: #51bd00;
					}
				}
			}
		}
		
	}
</style>
